import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
function ExpandableTableOfContents(props) {
  const identifier = typeof window !== "undefined" ? "expanded-" + btoa(window.location.pathname).substring(0, 8) : "expanded";
  const defaultItems = props.data.items.reduce((acc, item, index) => {
    acc[index] = item.items ? false : null;
    return acc;
  }, {});

  // Get the expanded items from local storage using window.location.pathname as unique identifier
  const initialExpanded = typeof window !== "undefined" ? JSON.parse(localStorage.getItem(identifier)) || defaultItems : defaultItems;
  const [expanded, setExpanded] = useState(initialExpanded);
  const [allExpanded, setAllExpanded] = useState(true);
  useEffect(() => {
    // if the expanded items are not in local storage, set them
    if (typeof window !== "undefined") {
      if (!localStorage.getItem(identifier)) {
        syncToLocalStorage(expanded);
      }
    }
  }, []);
  useEffect(() => {
    const shouldExpandAll = Object.values(expanded).some(val => val !== null);
    setAllExpanded(shouldExpandAll && Object.values(expanded).every(val => val === true));
  }, [expanded]);
  const syncToLocalStorage = expanded => {
    if (typeof window !== "undefined") {
      localStorage.setItem(identifier, JSON.stringify(expanded));
    }
  };
  const handleClick = index => {
    const expandedItems = {
      ...expanded,
      [index]: !expanded[index]
    };
    setExpanded(expandedItems);
    syncToLocalStorage(expandedItems);
  };
  const expandAll = () => {
    const expandedItems = props.data.items.reduce((acc, item, index) => {
      if (item.items) {
        acc[index] = true;
        if (!expanded[index]) {
          handleClick(index); // Expand the item if it's not already expanded
        }
      }

      return acc;
    }, {});
    setExpanded(expandedItems);
    setAllExpanded(true);
    syncToLocalStorage(expandedItems);
  };
  const collapseAll = () => {
    const expandedItems = props.data.items.reduce((acc, item, index) => {
      if (item.items) {
        acc[index] = false;
        if (expanded[index]) {
          handleClick(index); // Collapse the item if it's expanded
        }
      }

      return acc;
    }, {});
    setExpanded(expandedItems);
    setAllExpanded(false);
    syncToLocalStorage(expandedItems);
  };
  const shouldShowExpandAll = () => {
    return props.data.items.some(item => item.items);
  };
  return <Box sx={{
    borderLeft: "4px solid #eab911",
    paddingLeft: "19px",
    transform: "translateX(-21px)",
    marginBottom: 4
  }}>
      <Typography variant="h5" sx={{
      color: "text.primary",
      mt: "3rem",
      mb: ".5rem",
      fontSize: "20px !important",
      fontWeight: "900",
      lineHeight: "28px",
      letterSpacing: "0"
    }}>
        Table of Contents{" "}
        {shouldShowExpandAll() && <Button onClick={allExpanded ? collapseAll : expandAll} aria-label="show more" size="small" color="primary" sx={{
        ml: 1
      }}>
            {allExpanded ? "Collapse All" : "Expand All"}
          </Button>}
      </Typography>
      <Box>
        <ul>
          {props.data.items?.map((item, index) => <li key={index} style={{
          position: "relative"
        }}>
              <>
                <MuiLink component={Link} to={item.url} sx={{
              padding: "3px 0",
              margin: "2px",
              display: "inline-flex",
              flexDirection: "column",
              width: "auto"
              // marginLeft: "1.1em", // hack to add space to second line
              // textIndent: "-1.1em", // this too
            }}>
                  {item.title}
                </MuiLink>
                {item.items && <IconButton onClick={() => handleClick(index)} aria-expanded={expanded[index]} aria-label="show more" size="small" color="primary" sx={{
              height: "34px",
              position: {
                xs: "absolute",
                md: "relative"
              },
              top: {
                xs: "4px",
                md: "unset"
              }
            }}>
                    {expanded[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>}
              </>
              {item.items && <li>
                  <Collapse in={expanded[index]}>
                    <ul style={{
                marginLeft: "1.3rem"
              }}>
                      {item.items?.map((subItem, subIndex) => <li style={{
                  listStyleType: "disc"
                }} key={subIndex}>
                          <MuiLink component={Link} to={subItem.url} sx={{
                    padding: "0",
                    margin: "2px 2px 6px",
                    display: "inline-flex"
                  }}>
                            {subItem.title}
                          </MuiLink>
                        </li>)}
                    </ul>
                  </Collapse>
                </li>}
            </li>)}
        </ul>
      </Box>
    </Box>;
}
export default ExpandableTableOfContents;