import { alpha, List, ListItem, useTheme } from "@mui/material"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"

const FAQList = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allDirectory(
          filter: { dir: { regex: "/frequently-asked-questions/" } }
        ) {
          edges {
            node {
              base
              relativePath
            }
          }
        }
      }
    `
  )

  const directories = data.allDirectory.edges.map((edge) => edge.node.base)
  const theme = useTheme();

  const rh = (str) => {
    let nohyphen = str.replace(/-/g, " ")
    let capitalizedFirst = nohyphen.charAt(0).toUpperCase() + nohyphen.slice(1)
    return capitalizedFirst
  }

  return (
    <>
      <List>
        {directories.map((directory) => (
          <Link to={"/blog/frequently-asked-questions/" + directory}>
            <ListItem sx={{
              color: "text.primary",
              bgcolor: alpha(theme.palette.text.secondary , 0.21),
              padding: "14px",
              mb: 2,
              transition: "background 0.2s ease-in-out",
              '&:hover': {
                color: "text.primary",
                bgcolor: alpha(theme.palette.text.secondary , 0.14),
                transition: "background 0.2s ease-in-out",
              },
            }} key={directory}>{rh(directory)}</ListItem>
          </Link>
        ))}
      </List>
    </>
  )
}

export default FAQList
