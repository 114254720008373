import * as React from "react"
import { useEffect, useState } from "react"

import { MDXProvider } from "@mdx-js/react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"

// Utilities
// import { ClapButton } from "@lyket/react"
import kebabCase from "lodash/kebabCase"

// local components
import BlogLayout from "../components/layout-blog"
import shortcodes from "../components/MdxComponents"
import ProgressAppBar from "../components/ProgressAppBar"
import { SEO } from "../components/seo"
import Post from "../templates/post"

import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

import LinkIcon from "@mui/icons-material/Link"

const ClampTypography = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  lineClamp: "2",
  WebkitBoxOrient: "vertical",
}

function PostTags(props) {
  const tags = props.data
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {tags?.length > 0 && (
        <Typography
          variant="h3"
          sx={{
            color: "text.primary",
            fontSize: "16px !important",
            letterSpacing: 0,
            fontWeight: "500",
            lineHeight: "20px",
          }}
        >
          Topics
        </Typography>
      )}
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        {tags?.map((tag) => (
          <Chip
            key={tag}
            component={Link}
            to={`/blog/tag/${kebabCase(tag)}/`}
            label={tag}
            clickable
          />
        ))}
      </Box>
    </Box>
  )
}

function MoreStories(props) {
  const { previous, next } = props.data
  console.log("previous:", previous)

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        fontFamily: "Inter",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: "text.primary",
          fontSize: "16px !important",
          letterSpacing: 0,
          fontWeight: "500",
          lineHeight: "20px",
        }}
      >
        More stories
      </Typography>
      <Stack spacing={3}>
        {!previous && !next && (
          <Typography>No other stories in this category.</Typography>
        )}
        {[next, previous].map((item) => {
          if (!item) return null
          return (
            <Box
              key={item.fields.slug}
              component={Link}
              to={"/blog" + item.fields.slug}
              rel={item}
              sx={{
                textDecoration: "none",
                color: "unset",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1rem",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary" }}
                  gutterBottom
                >
                  {`${item.frontmatter.date} • ${item.timeToRead}`} min read
                </Typography>
                <Typography
                  variant="body1"
                  style={ClampTypography}
                  sx={{
                    color: "text.primary",
                    fontWeight: "700",
                    lineHeight: "20px",
                    letterSpacing: "0",
                    transition: "color 0.2s ease-in-out",
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  {" "}
                  {item.frontmatter.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "55px",
                  display: "flex",
                  height: "100%",
                  background: "black",
                  marginTop: "24px",
                  minHeight: "40px",
                }}
              >
                <GatsbyImage
                  image={getImage(item.frontmatter.featuredImage)}
                  alt={item.frontmatter.title}
                  style={{ borderRadius: "4px", aspectRatio: 1 }}
                />
              </Box>
            </Box>
          )
        })}
      </Stack>
    </Box>
  )
}

const PostDetailTemplate = ({ data, location }) => {
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      // scroll to top of element with id top #top - but only if there is no hash in the url
      if (!location.hash) {
        document.getElementById("top").scrollIntoView()
      }
    }
  }, [data, location])

  const post = data.mdx
  const image = getImage(post.frontmatter.featuredImage)
  const tags = post.frontmatter.tags

  const [open, setOpen] = React.useState(false)

  const isFAQ = post.frontmatter.title === "Frequently Asked Questions"

  const handleTooltipOpen = () => {
    setOpen(true)
    navigator.clipboard.writeText(
      `https://www.tonyebrown.com${location.pathname}`
    )
    setTimeout(() => {
      setOpen(false)
    }, 700)
  }

  //Width State
  const [width, setWidth] = useState(0)
  // scroll function
  const scrollHeight = () => {
    var el = document.documentElement,
      ScrollTop = el.scrollTop || document.body.scrollTop,
      ScrollHeight = el.scrollHeight || document.body.scrollHeight
    var percent = (ScrollTop / (ScrollHeight - el.clientHeight)) * 100
    // store percentage in state
    setWidth(percent)
  }

  //useEffect to control the component lifecycle
  useEffect(() => {
    window.addEventListener("scroll", scrollHeight)
    return () => window.removeEventListener("scroll", scrollHeight)
  })

  return (
    <BlogLayout
      location={location}
      title={""}
      extraDrawerContent={
        <>
          <PostTags data={tags} />
          <MoreStories data={data} />
        </>
      }
      extraFooterContent={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            pt: "4rem",
          }}
        >
          {data.next && <Divider />}
          {data.next && <Post data={data.next} />}
          {data.previous && <Divider />}
          {data.previous && <Post data={data.previous} />}
        </Box>
      }
    >
      <ProgressAppBar text={post.frontmatter.title} percent={width} />
      <SEO
        title={"Blog | " + post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article itemScope itemType="http://schema.org/Article">
        <Container
          className="post-detail"
          maxWidth="string"
          disableGutters
          sx={{
            maxWidth: "692px",
            "@media (max-width: 600px)": {
              px: "1.5rem",
            },
          }}
        >
          <div>
            {!isFAQ && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: ".5rem",
                  padding: "2.5rem 0",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <Avatar
                    alt="Tonye Brown"
                    src="/images/avatar.png"
                    sx={{
                      width: 48,
                      height: 48,
                      backgroundColor: "divider",
                      "@media (max-width: 900px)": {
                        width: 40,
                        height: 40,
                      },
                    }}
                  >
                    TB
                  </Avatar>
                  <Stack>
                    <Typography variant="body1">Tonye Brown</Typography>
                    <Typography color="text.secondary" variant="body2">
                      {post.frontmatter.date}
                      <Box
                        sx={{
                          display: "inline",
                          "@media (max-width: 600px)": {
                            display: "none",
                          },
                        }}
                      >
                        &nbsp;&bull;&nbsp;{post.timeToRead + " min read"}
                      </Box>
                    </Typography>
                  </Stack>
                </Box>
                {/* social share */}
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Tooltip
                    PopperProps={{ disablePortal: true }}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow
                    title="Copied link"
                  >
                    <IconButton onClick={handleTooltipOpen}>
                      <LinkIcon sx={{ transform: "rotate(-45deg)" }} />
                    </IconButton>
                  </Tooltip>

                  <Box
                    sx={{
                      fontSize: "15px",
                      color: "text.secondary",

                      "* > svg": {
                        color: "action.active",
                        fill: "currentColor",
                      },
                      "* > button": {
                        width: "40px !important",
                        height: "40px !important",
                        backgroundColor: "none",
                        transition:
                          "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        "&:hover": {
                          backgroundColor: "action.hover",
                          opacity: "initial",
                        },
                        "&:focus": {
                          backgroundColor: "action.selected",
                          transition:
                            "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        },
                      },
                    }}
                  >
                    {/* <Provider
                    apiKey="pt_9aaebf23b47b7acc590ce3fbcae458"
                    theme={{
                      colors: {
                        primary: "inherit",
                        text: "inherit",
                        highlight: "#6200EE20",
                      },
                    }}
                  > */}
                    {/* <ClapButton id={post.id} hideCounterIfLessThan={1} /> */}
                    {/* </Provider> */}
                  </Box>
                </Stack>
              </Box>
            )}
            <Box py={4} pt={isFAQ ? "4px" : 4}>
              <Box>
                <Typography
                  variant="h1"
                  gutterBottom
                  sx={{
                    fontWeight: "900",
                    letterSpacing: "-0.016em",
                    lineHeight: "40px",
                    fontSize: "32px !important",
                    "@media (max-width: 900px)": {
                      lineHeight: "36px",
                    },
                    "@media (max-width: 600px)": {
                      fontSize: "32px !important",
                    },
                  }}
                >
                  {post.frontmatter.title}
                </Typography>
                <Typography
                  variant="h2"
                  gutterBottom
                  sx={{
                    fontSize: "22px !important",
                    letterSpacing: "0",
                    lineHeight: "28px",
                    fontWeight: "400",
                    color: "text.disabled",
                    "@media (max-width: 600px)": {
                      fontSize: "22px !important",
                    },
                  }}
                >
                  {post.frontmatter.description}
                </Typography>
              </Box>
            </Box>
            {image && (
              <Box
                sx={{
                  maxHeight: "40rem",
                  pb: "1rem",
                  "& > div": { maxHeight: "40rem" },
                }}
              >
                <GatsbyImage image={image} alt={post.frontmatter.title} />
              </Box>
            )}
          </div>
          <MDXProvider components={shortcodes}>
            <MDXRenderer data={post.tableOfContents}>{post.body}</MDXRenderer>
          </MDXProvider>
        </Container>
      </article>
    </BlogLayout>
  )
}

export default PostDetailTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      timeToRead
      slug
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        featuredImage {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
          name
        }
      }
      tableOfContents
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      timeToRead
      frontmatter {
        title
        date(formatString: "MMM, YYYY")
        description
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
          name
        }
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      timeToRead
      frontmatter {
        title
        date(formatString: "MMM, YYYY")
        description
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
          name
        }
      }
    }
  }
`
