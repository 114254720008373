// defines mdx components styles to be used for post content
import * as React from "react"

import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import MuiLink from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import { Link } from "gatsby"
import ExpandableTableOfContents from './ExpandableTableOfContents'
import FAQList from "./FAQList"

const linkStyle = {
  color: "inherit",
  textDecorationColor: "inherit",
  background: "#00bcd414",
  padding: "3px 7px",
  borderRadius: "4px",
  transition: "background 0.3s ease-in-out",
  "&:hover": {
    background: "#00bcd430",
    color: "text.primary",
    transition: "background 0.3s ease-in-out",
  },
  ".gatsby-resp-image-background-image": {
    position: "absolute !important",
  },
  ".gatsby-resp-image-image": {
    marginTop: "0 !important",
    margin: ".2rem 0 !important",
  }
}

const shortcodes = {
  p: (props) => (
    <Typography
      variant="body1"
      sx={{
        color: "text.postBody",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "16px",
        lineHeight: "32px",
        // letterSpacing: "-0.003em",
        mt: "1.5rem",
        // wordBreak: "break-word",
        // hyphens: "auto",
        "@media (max-width: 600px)": {
          fontSize: "17px",
          lineHeight: "28px",
          mt: "1rem",
        },
      }}
      {...props}
    />
  ),
  h1: (props) => (
    <Typography
      variant="h2"
      sx={{
        mt: "3rem",
        mb: "2rem",
        fontSize: "24px !important",
        fontWeight: "900",
        lineHeight: "28px",
        letterSpacing: "0",
        "@media (max-width: 600px)": {
          fontSize: "20px !important",
          mt: "2.5rem",
        },
      }}
      {...props}
    />
  ),
  h2: (props) => (
    <Typography
      variant="h3"
      sx={{
        mt: "3rem",
        mb: "0",
        fontSize: "28px !important",
        letterSpacing: "0",
        fontWeight: "900",
        "@media (max-width: 600px)": {
          fontSize: "25px !important",
          mt: "2rem",
        },
      }}
      {...props}
    />
  ),
  h3: (props) => (
    <Typography
      variant="h3"
      sx={{
        position: "relative",
        fontSize: "21px !important",
        fontWeight: "600",
        marginTop: "3rem",
        paddingBottom: '4px',
        display: 'inline-block',
        borderBottom: '4px solid #00000014',
        color: '#2d3d3f'
        // borderLeft: "3px solid #ebba11",
        // paddingLeft: "10px",
      }}
      {...props}
    />
  ),
  blockquote: (props) => (
    <Typography
      variant="h2"
      sx={{
        pl: 2,
        my: "2rem",
        lineHeight: "32px",
        borderLeft: "3px solid ",
        borderColor: "#00bcd4",
        background: "#0003",
        padding: "12px 20px",
        "& > p": {
          // color: "text.disabled",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: "400",
          // letterSpacing: "-0.003em",
          lineHeight: "inherit",
          m: "unset",
        },
      }}
      {...props}
    />
  ),
  img: (props) => (
    <img {...props} style={{ maxWidth: "100%", height: "auto", marginTop: "2rem" }} />
  ),
  ul: (props) => (
    <ul
      {...props}
      style={{
        listStyleType: "square",
        paddingLeft: "1.1rem",
        marginBottom: "4rem",
        marginTop: "2rem",
      }}
    />
  ),
  ol: (props) => (
    <ol
      {...props}
      style={{
        paddingLeft: "1rem",
        listStyleType: "decimal",
      }}
    />
  ),
  li: (props) => (
    <li
      {...props}
      style={{
        // listStyleType: "square",
        marginTop: "1.14em",
        marginBottom: "-0.46em",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "16px",
        lineHeight: "32px",
        letterSpacing: "-0.003em",
        paddingLeft: ".5rem",
      }}
    />
  ),
  hr: (props) => (
    <Divider
      sx={{
        borderWidth: 0,
        textAlign: "center",
        height: "auto",
        "&:before": {
          content: '"..."',
          fontSize: "2rem",
          letterSpacing: "16px !important",
        },

      }}
      {...props}
    />
  ),
  a: (props) => (
    <MuiLink
      target={
        // if the link is external (that is, does not contain the site's domain name), open in a new tab
        props.href.includes("https://www.tonyebrown.com") ? "_self" : "_blank"
      }
      rel="noopener"
      {...props}
      color="inherit"
      sx={linkStyle}
    />
  ),
  mark: (props) => (
    <Typography
      sx={{
        backgroundColor: "primary.light",
        fontFamily: "inherit",
        fontSize: "inherit",
        lineHeight: "inherit",
        color: "inherit",
        display: "inline",
      }}
    >
      <mark {...props} style={{ backgroundColor: "inherit", color: "inherit" }}>
        {props.children}
      </mark>
    </Typography>
  ),
  figcaption: (props) => (
    <figcaption
      style={{ textAlign: "center", marginTop: "8px", marginBottom: "16px" }}
    >
      <Typography
        variant="caption"
        {...props}
        sx={{
          lineHeight: "20px",
          fontSize: "14px",
          color: "text.disabled",
          letterSpacing: 0,
        }}
      ></Typography>
    </figcaption>
  ),
  TableOfContents: (props) => (
    <ExpandableTableOfContents {...props} sx={{ mt: "2rem" }} />
  ),
  Alert: (props) => (
    <Alert
      sx={{
        backgroundColor: "background.alt",
        lineHeight: "20px",
        fontSize: "14px",
        letterSpacing: 0,
        my: "1.5rem",
        "@media (max-width: 600px)": { my: "1rem" },
      }}
      {...props}
    />
  ),
  Link: (props) => <MuiLink component={Link} sx={linkStyle} {...props} />,
  SpecialLink: (props) => (
    <MuiLink href={props.href} underline="none" color={"inherit"}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 4,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: "1rem",
          }}
        >
          <Typography sx={{ fontWeight: "600", fontSize: "17px" }}>
            {props.title}
          </Typography>
          <Typography
            sx={{ color: "text.postBody", lineHeight: "20px" }}
            gutterBottom
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              lineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {props.description}
          </Typography>
          <Typography sx={{ color: "text.disabled", fontSize: "14px" }}>
            {props.site}
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundImage: `url(${props.image})`,
            width: "100%",
            maxWidth: "140px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            "@media (max-width: 600px)": {
              maxWidth: "120px",
            },
          }}
        ></Box>
      </Box>
    </MuiLink>
  ),

  Box: (props) => <Box {...props} />,
  Typography: (props) => <Typography {...props} />,
  FAQList: (props) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FAQList />
    </Box>
  ),
  OpticWeatherCallout1: (props) => (
    <Box
      sx={{
        display: "flex",
        fontSize: "13px",
        lineHeight: "24px",
        letterSpacing: "0",
        background: "#00bcd4",
        color: "#000",
        padding: "2rem 3vw",
        margin: "4rem 0",
      }}
      {...props}
    >
      <img
        src="/screenshot7.png"
        alt="Optic Weather Logo"
        style={{ height: "100%" }}
      />
      <Typography variant="p" sx={{}}>
        Optic Weather features a unique artificial intelligence system that
        summarizes weather forecasts in human language and provides personalized
        advice based on your location. With immersive features like sound
        effects, videos, and background music, Optic Weather brings the weather
        to life like never before.
        <Link to="/">
          <Typography
            variant="button"
            sx={{
              display: "flex",
              background: "#ffffff54",
              padding: "8px 10px",
              marginTop: "12px",
              justifyContent: "center",
              borderRadius: "34px",
            }}
          >
            Try Optic Weather
          </Typography>
        </Link>
      </Typography>
    </Box>
  ),
}

export default shortcodes
